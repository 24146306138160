import React from 'react'
import styles from '../../styles/styles'
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import Header from '../../components/Layout/Header';

const ShopPreviewPage = () => {
  return (
    <>
      <Header activeHeading={5} />
      <div className={`${styles.section} w-3/4 bg-[#f5f5f5] px-10`}>
        <div className="justify-between w-full py-10 800px:flex">
          <div className="800px:w-[25%] bg-[#fff] rounded-[4px] shadow-sm 800px:overflow-y-scroll 800px:h-[90vh] 800px:sticky top-10 left-0 ">
            <ShopInfo isOwner={false} />
          </div>
          <div className="800px:w-[72%] mt-5 800px:mt-['unset'] rounded-[4px]">
            <ShopProfileData isOwner={false} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopPreviewPage