import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";

const BestDeals = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    const firstFive = sortedData && sortedData.slice(0, 5);
    setData(firstFive);
  }, [allProducts]);


  return (
    <div className="flex items-center justify-center w-full">
      <div className="w-[90%] 800px:w-[80%]">
        <div className={`${styles.section} `}>
          <div className={`${styles.heading}`}>
            <h1>Best Deals</h1>
          </div>
          <div className="grid grid-cols-1 gap-[10px] md:grid-cols-2 md:gap-[15px] lg:grid-cols-4 lg:gap-[15px] xl:grid-cols-4 xl:gap-[15px] mb-12 border-0">
            {
              data && data.length !== 0 && (
                <>
                  {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestDeals;
