import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import Logo from "../../Assests/images/logo-white.svg";

const Footer = () => {
  return (
    <div className="flex justify-center items-center w-full bg-[#000]">
      <div className=" w-[90%] 800px:w-[80%]  text-white flex items-center flex-col">
        <div className="w-[85%]">
          <div className="grid grid-cols-1 gap-6 py-16 sm:gird-cols-3 lg:grid-cols-3 sm:px-8 sm:text-center">
            <ul className="flex flex-col items-center px-5 text-center sm:text-start sm:block">
              <img
                src={Logo}
                alt=""
                style={{ filter: "brightness(1) invert(0)" }}
              />
              <br />
              <p>The home and elements needeed to create beatiful products.</p>
              <div className="flex items-center mt-[15px]">
                <AiFillFacebook size={25} className="cursor-pointer" />
                <AiOutlineTwitter
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
                <AiFillInstagram
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
                <AiFillYoutube
                  size={25}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
              </div>
            </ul>

            <ul className="flex flex-col items-center text-center sm:text-start">
              <h1 className="mb-1 font-semibold">Company</h1>
              {footerProductLinks.map((link, index) => (
                <div className="flex flex-row">
                  <li key={index} className="">
                    <Link
                      className="text-sm leading-6 text-gray-400 duration-300 cursor-pointer hover:text-red-400"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                </div>
              ))}
            </ul>

            <ul className="flex flex-col items-center text-center sm:text-start">
              <h1 className="mb-1 font-semibold">About Hageregn Store</h1>
              <p className="w-1/2 text-gray-400">
                Increase subscribers with beautiful landing pages, sign up forms, and link pages that make your work stand out.
              </p>
            </ul>

            {/* <ul className="text-center sm:text-start">
            <h1 className="mb-1 font-semibold">Support</h1>
          </ul> */}
          </div>
        </div>
        <div className="flex items-center justify-center w-full">
          <div
            className="grid grid-cols-1 gap-10 pt-2 pb-8 text-sm text-center text-gray-400 sm:grid-cols-2 lg:grid-cols-3 "
          >
            <span>© 2024 <span>Bilih</span> <span>Digital</span><span>.</span> All rights reserved.</span>
            <span>Terms · Privacy Policy</span>
            <div className="flex items-center justify-center w-full sm:block">
              <img
                src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
                alt=""
              />
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export default Footer;
