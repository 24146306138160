import React from 'react'
import { Link } from 'react-router-dom'
import { navItems } from '../../static/data'
import styles from '../../styles/styles'
import UnderArmed from '../../Assests/images/under-arm.svg';

const Navbar = ({ active }) => {
  return (
    <div className={`block 800px:${styles.noramlFlex}`}>
      {
        navItems && navItems.map((i, index) => (
          <div className="relative flex flex-col items-center justify-center hidden lg:flex align-center">
            <Link to={i.url}
              className={`${active === index + 1 ? "text-white" : "text-black 800px:text-[#fff]"} pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer}`}
            >
              {i.title}
            </Link>
            {active === index + 1 && <img className='w-16' src={UnderArmed} />}

          </div>

        ))
      }
    </div>
  )
}

export default Navbar