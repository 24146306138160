import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
      className={`w-full flex justify-center `}>
      <div className={`w-[90%] 800px:w-[85%] py-10 justify-center`}>
        <MyComponent />
      </div>
    </div>
  );
};

export default Hero;



function MyComponent(props) {
  return (
    <div>
      <div className="flex justify-center gap-5 max-md:flex-col max-md:gap-0">
        <div className="w-1/2 max-md:ml-0 max-md:w-full">
          <div className="bg-blue-400 flex overflow-hidden relative flex-col grow justify-center px-8 pt-12 pb-4 rounded-3xl min-h-[600px] max-md:pl-5 max-md:mt-8 max-md:max-w-full">
            <img
              loading="lazy"
              src="https://mamunuiux.com/shopo/uploads/custom-images/slider-2022-10-27-01-36-44-4193.png"
              className="absolute inset-0 object-cover w-full h-full"
            />
            <div className="relative justify-center bg-white self-start px-2.5 py-2 mt-24 text-xs font-semibold leading-4 uppercase whitespace-nowrap rounded-full shadow text-[color(display-p3_0.1333_0.1333_0.1333)] max-md:mt-10">
              New released
            </div>
            <div className="relative mt-9 text-5xl leading-[50px] text-[color(display-p3_0.1333_0.1333_0.1333)] max-md:max-w-full max-md:text-4xl">
              Hageregna <br /> Clothing Product <br />
            </div>



            <div className="relative flex rounded mt-14 max-md:mt-10">
              <div className="relative flex flex-col content-center justify-center w-[20%] align-middle">
                <div className="flex flex-row items-center text-sm font-medium text-neutral-800">
                  Shop Now
                  <div className="ml-2">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd7c4dc7b94a3b4f1028e3b6ae4d871ed585050e8b75994635e07aa3d5b0b1a8?"
                      className="w-[7px]"
                    />
                  </div>
                </div>
                <div className="w-[82px] h-0.5 left-0 top-[28px]  bg-amber-400" />
              </div>
            </div>

            <div className="relative mt-1.5 h-0.5 w-[82px]" />
            <div className="flex relative gap-2.5 self-end mt-32 w-[70px] max-md:mt-10">
              <div className="flex-1 h-1 shadow-sm shrink-0 rounded-xl" />
              <div className="flex-1 h-1 shadow-sm shrink-0 rounded-xl" />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between w-1/4 max-md:ml-0 max-md:w-full">

          <div className="flex flex-col justify-between h-full font-semibold max-md:mt-8 rounded-2xl">

            {/* <div className="flex flex-col items-start justify-center w-full h-[250px] bg-green-700 rounded-2xl ">
              <div className="rounded-3xl">
                <div className="relative ">
                  <div className="">
                    <div className="bg-white rounded-full shadow">
                      <div className=" text-neutral-800 text-xs font-semibold font-['Inter'] uppercase leading-none bg-white">Gaming</div>
                    </div>
                    <div className="w-[85.13px] h-[30px] left-0 top-[47px] absolute text-neutral-800 text-3xl font-semibold font-['Inter'] leading-[30px]">Apple</div>
                    <div className="w-[188.33px] h-10 left-0 top-[89px] absolute text-neutral-800 text-3xl font-semibold font-['Inter'] leading-10">Smart Watch</div>
                  </div>
                  <div className="">
                    <div className=" text-neutral-800 text-sm font-medium font-['Inter'] leading-[30px]">Shop Now</div>
                    <div className="" />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex overflow-hidden  flex-col  h-[280px] bg-red-400 justify-center items-start  pr-20 pl-10  w-full rounded-2xl ">
              {/* <img
                  loading="lazy"
                  srcSet="https://mamunuiux.com/shopo/uploads/website-images/Mega-menu-2022-10-27-01-42-01-1798.png"
                  className="inset-0 w-full h-full "
                /> */}
              <div className="justify-center px-10 py-2 text-xs uppercase bg-white rounded-full shadow whitespace-nowrap max-md:px-5">
                Gaming
              </div>
              <div className=" mt-7 leading-[100%]">Xbox</div>
              <div className="mt-6 whitespace-nowrap">5th Version</div>
              <div className="flex  gap-2 pr-2 mt-12 mb-1.5 text-sm font-medium leading-7 max-md:mt-10">
                <div className="flex-auto">Shop Now</div>

              </div>
            </div>

            <div className="flex overflow-hidden  flex-col  h-[280px] bg-red-400 justify-center items-start py-12 pr-20 pl-10 mt-8 w-full rounded-2xl aspect-[1.4] max-md:px-5">
              {/* <img
                  loading="lazy"
                  srcSet="https://mamunuiux.com/shopo/uploads/website-images/Mega-menu-2022-10-27-01-42-01-1798.png"
                  className="inset-0 w-full h-full "
                /> */}
              <div className="justify-center px-10 py-2 text-xs uppercase bg-white rounded-full shadow whitespace-nowrap max-md:px-5">
                Gaming
              </div>
              <div className=" mt-7 leading-[100%]">Xbox</div>
              <div className="mt-6 whitespace-nowrap">5th Version</div>
              <div className="flex  gap-2 pr-2 mt-12 mb-1.5 text-sm font-medium leading-7 max-md:mt-10">
                <div className="flex-auto">Shop Now</div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}


