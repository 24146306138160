import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styles from '../../styles/styles'
import EventCard from "./EventCard";

const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);

  return (
    <div className='flex justify-center '>
      <div className='w-[90%] 800px:w-[73%]'>
        {
          !isLoading && (
            <div className={``}>
              <div className={`${styles.heading}`}>
                <h1>Popular Events</h1>
              </div>

              <div className="grid w-full">
                {
                  allEvents.length !== 0 && (
                    <EventCard data={allEvents && allEvents[0]} />
                  )
                }
                <h4>{
                  allEvents?.length === 0 && (
                    'No Events have!'
                  )
                }

                </h4>
              </div>

            </div>
          )
        }
      </div>
    </div>
  )
}

export default Events